<div class="container-fluid simple-navbar d-flex justify-content-between align-items-center">
    <div class="logo">

        <img src="./assets/images/logo.png" style="width: 180px ;" alt="">
    </div>


    <div>

        <div class="d-flex align-items-center justify-content-start ">
            <a *ngIf="isLoggedIn" [routerLink]="'/app'" class="btn btn-dark btn-sm me-3">GET STARTED</a>

            <span>
                <div ngbDropdown class="d-inline-block">
                    <a type="button" *ngIf="isLoggedIn" id="user-circle" ngbDropdownToggle>
                        <fa-icon [icon]="faUserCircle" [size]="'2x'"></fa-icon>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="user-circle">
                        <a ngbDropdownItem [routerLink]="['../auth/login']">LOGOUT</a>
                    </div>
                </div>

                <span *ngIf="!isLoggedIn">
                    <button class="btn btn-link" [routerLink]="'/auth/login'">LOGIN</button>
                </span>
            </span>
        </div>
    </div>
</div>
<div class="home-details">
    <!-- Hero Section -->

    <section>
        <div class="container mt-4">
            <div class="row align-items-center">
                <div class="col-6">
                    <h1>Effortless Scheduling & Planning for Professionals</h1>
                    <p>Stay on top of your goals, manage tasks, and collaborate with ease.</p>
                    <a *ngIf="isLoggedIn" [routerLink]="'/app'" class="btn btn-primary-custom">GET STARTED</a>
                    <a *ngIf="!isLoggedIn" [routerLink]="'/auth/login'" class="btn btn-primary-custom">LOGIN</a>
                </div>

                <div class="col-6">
                    <img src="./assets/images/461872-PFOXZ3-313.jpg" alt="">
                </div>
            </div>
        </div>

    </section>

    <!-- Features Section -->
    <section id="features" class="py-80">
        <h2 class="section-title text-center">Powerful Features</h2>
        <div class="container">
            <div class="row">
                <div class="col-md-4 mb-4">
                    <div class="card feature-card">
                        <img src="./assets/images/smart-calendar.png" class="card-img-top" alt="Smart Calendar">
                        <div class="card-body">
                            <h5 class="card-title">Smart Calendar</h5>
                            <p class="card-text">Sync with popular calendars, set recurring events, and never forget an
                                appointment again.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card feature-card">
                        <img src="./assets/images/task-management.png" class="card-img-top" alt="Task Management">
                        <div class="card-body">
                            <h5 class="card-title">Task Management</h5>
                            <p class="card-text">Track tasks, set deadlines, and prioritize work to maximize
                                productivity.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <div class="card feature-card">
                        <img src="./assets/images/team-collaboration.jpg" class="card-img-top" alt="Team Collaboration">
                        <div class="card-body">
                            <h5 class="card-title">Team Collaboration</h5>
                            <p class="card-text">Work with your team, assign tasks, share updates, and stay in sync.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- Pricing Section -->
    <section id="pricing" class="bg-light py-80">
        <h2 class="section-title text-center">Choose Your Plan</h2>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="card pricing-card">
                        <div class="pricing-card-header">
                            <h4>Basic Plan</h4>
                        </div>
                        <div class="pricing-card-body">
                            <h3>₹1100/month</h3>
                            <ul>
                                <li>1 User</li>
                                <li>Task Tracking</li>
                                <li>Basic Calendar Sync</li>
                            </ul>
                            <a [routerLink]="['../auth/create-account']" class="btn btn-primary-custom">Sign Up</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="card pricing-card">
                        <div class="pricing-card-header">
                            <h4>Premium Plan</h4>
                        </div>
                        <div class="pricing-card-body">
                            <h3>₹2200/month</h3>
                            <ul>
                                <li>Up to 5 Users</li>
                                <li>Advanced Task Management</li>
                                <li>Team Collaboration</li>
                            </ul>
                            <a [routerLink]="['../auth/create-account']" class="btn btn-primary-custom">Sign Up</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <div class="card pricing-card">
                        <div class="pricing-card-header">
                            <h4>Enterprise Plan</h4>
                        </div>
                        <div class="pricing-card-body">
                            <h3>Starts from ₹6000/month</h3>
                            <ul>
                                <li>Unlimited Users</li>
                                <li>Custom Integrations</li>
                                <li>Priority Support</li>
                            </ul>
                            <!-- <a href="#" class="btn btn-primary-custom">Contact Us</a> -->

                            <p class="h3">Coming Soon</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Contact Section -->
    <section id="contact" class="py-80 text-center">
        <h2 class="section-title">Get in Touch</h2>
        <p>If you have any questions or want to schedule a demo, feel free to reach out!</p>
        <a href="mailto:support@planmaster.com" class="btn btn-primary-custom">Contact Support</a>
    </section>

    <!-- Footer -->
    <footer>
        <p>&copy; 2024 PlanMaster. All rights reserved.</p>
        <div class="social-icons">
            <a href="https://facebook.com" target="_blank" class="fab fa-facebook">
                Facebook
            </a>
            <a href="https://twitter.com" target="_blank" class="fab fa-twitter">
                Twitter
            </a>
            <a href="https://linkedin.com" target="_blank" class="fab fa-linkedin">
                LinkedIn
            </a>
        </div>
    </footer>
</div>