import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBell, faCalendarAlt, faCalendarCheck, faCogs, faCreditCard, faUserCheck, faUserCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SharedUserService } from '../shared/services/shared/user/shared-user.service';
import { CommonModule } from '@angular/common';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-about',
    imports: [RouterModule, FontAwesomeModule, CommonModule, NgbDropdownModule],
    templateUrl: './about.component.html',
    styleUrl: './about.component.scss'
})
export class AboutComponent implements OnInit {
  public faBell: IconDefinition = faBell;
  public faCalendarAlt: IconDefinition = faCalendarAlt;
  public faCalendarCheck: IconDefinition = faCalendarCheck;
  public faUserCheck: IconDefinition = faUserCheck;
  public faCogs: IconDefinition = faCogs;
  public faCreditCard: IconDefinition = faCreditCard;
  public faUserCircle: IconDefinition = faUserCircle;

  public isLoggedIn: boolean = false;

  constructor(private titleService: Title, private metaService: Meta, private _sharedUserService: SharedUserService) { }


  public ngOnInit(): void {

    this.titleService.setTitle('ClockFlex - Ultimate Scheduling & Booking Solution');

    // Set meta tags for SEO
    this.metaService.updateTag({ name: 'description', content: 'ClockFlex offers a seamless scheduling and booking solution for businesses and professionals to manage appointments, bookings, and more.' });
    this.metaService.updateTag({ name: 'keywords', content: 'ClockFlex, scheduling, booking, appointments, business scheduling, event planning, calendar integration' });
    this.metaService.updateTag({ name: 'robots', content: 'index, follow' }); // Allows search engines to index the page
    this.metaService.updateTag({ property: 'og:title', content: 'ClockFlex - Ultimate Scheduling & Booking Solution' });
    this.metaService.updateTag({ property: 'og:description', content: 'ClockFlex is the ultimate scheduling and booking solution for businesses, freelancers, and professionals.' });
    this.metaService.updateTag({ property: 'og:image', content: './assets/images/logo.jpg' });
    this.metaService.updateTag({ property: 'og:url', content: 'https://www.clockflex.com' });
    this.metaService.updateTag({ name: 'twitter:title', content: 'ClockFlex - Ultimate Scheduling & Booking Solution' });
    this.metaService.updateTag({ name: 'twitter:description', content: 'ClockFlex helps businesses streamline their booking and scheduling process with ease.' });
    this.metaService.updateTag({ name: 'twitter:image', content: './assets/images/logo.jpg' });
    this.subscribeUserService();
  }

  private subscribeUserService(): void {
    this._sharedUserService.loggedIn$.subscribe((response) => {
      this.isLoggedIn = (response) ? true : false;
    });
  }
}
