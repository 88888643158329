<ngb-toast *ngFor="let toast of toastService.toasts" [class]="toast.classname" class="text-center" [autohide]="true"
  style="z-index: 1200;" [delay]="toast.delay || 5000" (hidden)="toastService.remove(toast)">
  <fa-icon [icon]="faPoo" [size]="'xl'" *ngIf="toast.classname== 'bg-danger text-light'"></fa-icon>
  <fa-icon [icon]="faCircleCheck" [size]="'xl'" *ngIf="toast.classname== 'bg-success text-light'"></fa-icon>

  <div>
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>{{ toast.textOrTpl }}</ng-template>
  </div>

</ngb-toast>