import { Component, TemplateRef } from '@angular/core';
import { ToastService } from '../../services/shared/toast/toast.service';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleCheck, faPoo, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-toast-control',
  standalone: true,
  imports: [NgbToastModule, CommonModule, FontAwesomeModule],
  templateUrl: './toast-control.component.html',
  styleUrl: './toast-control.component.scss',
})
export class ToastControlComponent {

  public faPoo: IconDefinition = faPoo;
  public faCircleCheck: IconDefinition = faCircleCheck;

  constructor(public toastService: ToastService) { }

  public isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
