import { Component, OnInit } from '@angular/core';
import {
  faUserCircle,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebook,
  faXTwitter,
  faInstagram,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';

import { SharedUserService } from '../shared/services/shared/user/shared-user.service';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  standalone: false
})
export class HomeComponent implements OnInit {
  public faUserCircle: IconDefinition = faUserCircle;
  public faInstagram = faInstagram;
  public faXTwitter = faXTwitter;
  public faLinkedin = faLinkedin;
  public faFacebook = faFacebook;

  public isLoggedIn: boolean = false;

  constructor(private _sharedUserService: SharedUserService, private titleService: Title, private metaService: Meta) { }

  public ngOnInit(): void {

    this.titleService.setTitle('ClockFlex - Effortless Booking, Scheduling & Planning for Professionals');
    // Add/update meta tags
    this.metaService.addTags([
      { name: 'description', content: 'ClockFlex (clock flex) is a powerful tool for booking, scheduling, and planning. Stay on top of your goals, manage tasks, and collaborate with ease.' },
      { name: 'keywords', content: 'ClockFlex, clock flex, resources, resource, booking, scheduling, booking, task management, team collaboration, productivity, planning' },
      { name: 'author', content: 'ClockFlex Team' },
      { name: 'robots', content: 'index, follow' },
      { property: 'og:title', content: 'ClockFlex - Effortless Booking, Scheduling & Planning for Professionals' },
      { property: 'og:description', content: 'Stay on top of your goals with ClockFlex. Manage tasks, collaborate with your team, and simplify scheduling.' },
      { property: 'og:image', content: 'https://clockflex.com/assets/images/og-image.jpg' },
      { property: 'og:url', content: 'https://clockflex.com/home' },
      { property: 'og:type', content: 'website' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'ClockFlex - Effortless Booking, Scheduling & Planning for Professionals' },
      { name: 'twitter:description', content: 'Simplify scheduling, manage tasks, and collaborate seamlessly with ClockFlex.' },
      { name: 'twitter:image', content: 'https://clockflex.com/assets/images/twitter-image.jpg' },
    ]);
    this.subscribeUserService();
  }

  private subscribeUserService(): void {
    this._sharedUserService.loggedIn$.subscribe((response) => {
      this.isLoggedIn = (response) ? true : false;
    });
  }
}
