import { Component } from '@angular/core';
import { InternetService } from './shared/services/shared/internet/internet.service';
import { SpinnerService } from './shared/services/shared/spinner/spinner.service';
import { SharedUserService } from './shared/services/shared/user/shared-user.service';
import { Subscription } from 'rxjs';
import { SharedProgressBarService } from './shared/services/shared/progress-bar/shared-progress-bar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  public showProgressbar: boolean = false;
  public name: string = null!;
  public spinnerText: string = null!;
  public isLoading: boolean = false;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _internetService: InternetService,
    private _spinnerService: SpinnerService,
    private _sharedUserService: SharedUserService,
    private _sharedProgressBarService: SharedProgressBarService,
  ) { }

  public ngOnInit(): void {
    this._subscribeInternetService();
    this._subscribeSpinnerService();
    this._subscribeUserService();
    this.subscribeOrganizationProgressbarService();
  }

  private _subscribeInternetService(): void {
    this._internetService.subscribeInternetEvents();
  }

  public _subscribeSpinnerService(): void {
    let sub = this._spinnerService.spinnerText$.subscribe(
      (text) => (this.spinnerText = text)
    );
    this._subscriptions.push(sub);
  }

  public ngOnDestroy(): void {
    this._internetService.unsubscribeInternetEvents();
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  private _subscribeUserService(): void {
    this._sharedUserService.get();
  }

  private subscribeOrganizationProgressbarService(): void {
    let sub = this._sharedProgressBarService.isOrganizationProgressbarLoading$.subscribe((response) => {
      this.isLoading = response;
    });
    this._subscriptions.push(sub);
  }
}
