import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { SharedUserService } from '../../shared/user/shared-user.service';
import { map, switchMap, take } from 'rxjs';
import { SharedOrganizationService } from '../../shared/organization/shared-organization.service';



export class HeadersRequestInterceptor implements HttpInterceptor {

  constructor(private _sharedOrganizationService: SharedOrganizationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let _userService = inject(SharedUserService);
    let _organizationService = inject(SharedOrganizationService);

    return _userService.loggedIn$.pipe(
      take(1),
      switchMap((loggedIn) => {
        return _organizationService.organization$.pipe(
          take(1),
          switchMap((organization) => {

            if (loggedIn?.user?.id) {
              req = req.clone({
                setHeaders: {
                  userId: loggedIn?.user?.id,
                }
              });
            }

            if (loggedIn?.token) {
              req = req.clone({
                setHeaders: {
                  Authorization: 'Bearer ' + loggedIn?.token,
                }
              });
            }

            if (organization?.id) {
              req = req.clone({
                setHeaders: {
                  organizationId: organization?.id
                }
              });
            }

            return next.handle(req).pipe(map((event: HttpEvent<any>) => {
              if (event.type === 4) {
                this.modifyBody(event.headers);
              }
              return event;
            }));
          })
        );
      })
    );
  }

  private modifyBody(headrs: HttpHeaders) {
    let calendarId = headrs.get('x-calendarid');
    let calendarVersion = headrs.get('x-calendar-version');
    if (calendarId && calendarVersion) {
      this._sharedOrganizationService.setCalendar(calendarId, calendarVersion);
    }
  }
}
