import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUserCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { SharedUserService } from '../../shared/services/shared/user/shared-user.service';
import { NgbAccordionModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Texts } from '../../shared/constants/app-constants';

@Component({
  selector: 'app-documentation',
  imports: [CommonModule, RouterModule, FontAwesomeModule, NgbDropdownModule, NgbAccordionModule],
  templateUrl: './documentation.component.html',
  styleUrl: './documentation.component.scss'
})
export class DocumentationComponent {
  public document: IDocument = DocumentData;
  public isLoggedIn: boolean = false;
  public faUserCircle: IconDefinition = faUserCircle;

  constructor(private _sharedUserService: SharedUserService) { }

  public ngOnInit(): void {
    this.subscribeUserService();
  }

  private subscribeUserService(): void {
    this._sharedUserService.loggedIn$.subscribe((response) => {
      this.isLoggedIn = (response) ? true : false;
    });
  }

  public replace(text: string): string {
    return text;
  }
}


interface IDocument {
  topics: ITopic[];
}

interface ITopic {
  id: number;
  title: string;
  subtopics: ISubtopic[];
  description: string;
}

interface ISubtopic {
  id: number;
  title: string;
  description: string;
}

export const DocumentData: IDocument = {

  topics: [


    {
      "id": 1,
      "title": "User Accounts & Login",
      "subtopics": [
        {
          "id": 1,
          "title": "Create Account & Login",
          "description": "<div style=\"font-family: Arial, sans-serif; line-height: 1.6; max-width: 700px;\"><p style=\"font-size: 18px; font-weight: bold; margin-bottom: 10px;\">Creating an Account on ClockFlex</p><p style=\"font-size: 16px; font-weight: bold; margin-top: 20px;\">Steps to Create an Account</p><ol style=\"margin-top: 5px; padding-left: 20px;\"><li>Go to the <strong>ClockFlex website</strong>.</li><li>Click <strong>Sign Up</strong>.</li><li>Enter your <strong>email, password, and name</strong>.</li><li>Click <strong>Create Account</strong>.</li><li>After logging in, verify your email.</li></ol><p style=\"font-size: 16px; font-weight: bold; margin-top: 20px;\">Login Process</p><ol style=\"margin-top: 5px; padding-left: 20px;\"><li>Go to the <strong>ClockFlex Login Page</strong>.</li><li>Enter your registered <strong>email and password</strong>.</li><li>Click <strong>Login</strong>.</li></ol></div>"
        }
      ],
      "description": "Guidelines for creating an account, logging in, verifying email after login, resetting passwords, and logging out of ClockFlex."
    },
    {
      "id": 2,
      "title": "Organizations",
      "subtopics": [
        {
          "id": 1,
          "title": "Create an Organization",
          "description": "<div style=\"font-family: Arial, sans-serif; line-height: 1.6; max-width: 700px;\"><p style=\"font-size: 18px; font-weight: bold; margin-bottom: 10px;\">Creating an Organization in ClockFlex</p><ol style=\"margin-top: 5px; padding-left: 20px;\"><li>After logging in, you will land on the <strong>Organizations Page</strong>.</li><li>Click <strong>Create Organization</strong>.</li><li>Enter your organization’s <strong>name</strong> and other required details.</li><li>Click <strong>Create</strong> to finish setup.</li><li>You will be redirected to your new organization’s dashboard.</li></ol></div>"
        }
      ],
      "description": "Instructions on creating an organization in ClockFlex."
    },
    {
      "id": 3,
      "title": "Appointment Types",
      "subtopics": [
        {
          "id": 1,
          "title": "Create Appointment Type",
          "description": "<div style=\"font-family: Arial, sans-serif; line-height: 1.6; max-width: 700px;\"><p style=\"font-size: 18px; font-weight: bold; margin-bottom: 10px;\">Creating an Appointment Type in ClockFlex</p><ol style=\"margin-top: 5px; padding-left: 20px;\"><li>Go to your <strong>Organization Dashboard</strong>.</li><li>Click on <strong>Appointment Types</strong>.</li><li>Click <strong>Create New</strong>.</li><li>Enter the <strong>name, duration, and description</strong> of the appointment type.</li><li>Choose if this appointment type should be <strong>available for booking</strong>.</li><li>Set pricing (if applicable).</li><li>Click <strong>Save</strong> to finish.</li></ol></div>"
        }
      ],
      "description": "Learn how to create different types of appointments in ClockFlex."
    },
    {
      "id": 4,
      "title": "Availability",
      "subtopics": [
        {
          "id": 1,
          "title": "Set Up Availability",
          "description": "<div style=\"font-family: Arial, sans-serif; line-height: 1.6; max-width: 700px;\"><p style=\"font-size: 18px; font-weight: bold; margin-bottom: 10px;\">Managing Calendar for Resource-Based Scheduling</p><ol style=\"margin-top: 5px; padding-left: 20px;\"><li>Go to your <strong>Organization Dashboard</strong>.</li><li>Add or manage availability for specific calendar.</li><li>Define booking slots based on resource availability.</li><li>Attach appointment types that the resource handles.</li><li>Click <strong>Save</strong> to confirm changes.</li></ol></div>"
        }
      ],
      "description": "Learn how to configure and manage your availability, including calendar-based resource scheduling, in ClockFlex."
    },
    {
      "id": 5,
      "title": "Online Workspaces",
      "subtopics": [
        {
          "id": 1,
          "title": "Zoom Workplace",
          description: `<div style="font-family: Arial, sans-serif; line-height: 1.6; max-width: 700px;">
          <p style="font-size: 18px; font-weight: bold; margin-bottom: 10px;">
            How to Connect, Use, and Remove Zoom with ClockFlex
          </p>
        
          <p style="font-size: 16px; font-weight: bold; margin-top: 20px;">Requirements</p>
          <ul style="margin-top: 5px; padding-left: 20px;">
            <li>A <strong>Zoom account</strong> is required.</li>
            <li>You must have <strong>Administrator permissions</strong> in ClockFlex.</li>
          </ul>
        
          <p style="font-size: 16px; font-weight: bold; margin-top: 20px;">Key Rules</p>
          <ul style="margin-top: 5px; padding-left: 20px;">
            <li><strong>ClockFlex can only connect to one Zoom account at a time.</strong></li>
            <li><strong>Each Zoom account can only connect to one ClockFlex instance.</strong></li>
          </ul>
        
          <p style="font-size: 16px; font-weight: bold; margin-top: 20px;">Connecting Zoom to ClockFlex</p>
          <ol style="margin-top: 5px; padding-left: 20px;">
            <li>Navigate to the <strong>Integrations panel</strong> in ClockFlex.</li>
            <li>Locate <strong>Zoom</strong> and click <strong>Set up</strong>.</li>
            <li>Click <strong>Connect to Zoom</strong>.</li>
            <li>Log into Zoom using your credentials or via <strong>SSO, Google, or Facebook</strong>.</li>
            <li>Click <strong>Pre-approve</strong>, then click <strong>Authorize</strong>.</li>
            <li>Select the <strong>appointment types</strong> you want Zoom to work with.</li>
            <li>Customize your <strong>Zoom meeting settings</strong> as needed.</li>
            <li>Click <strong>Save</strong>.</li>
          </ol>
        
          <p style="font-size: 16px; font-weight: bold; margin-top: 20px;">Using Zoom for Appointments</p>
        
          <p style="margin-top: 10px; font-weight: bold;">Appointment Creation</p>
          <ul style="margin-top: 5px; padding-left: 20px;">
            <li>When an appointment is <strong>booked</strong>, ClockFlex will <strong>automatically schedule a Zoom meeting</strong> if Zoom is enabled for that appointment type.</li>
            <li>The Zoom link will be included in:
              <ul style="padding-left: 20px;">
                <li>The <strong>confirmation email</strong> sent to the client.</li>
                <li>The <strong>appointment details</strong> in ClockFlex.</li>
              </ul>
            </li>
          </ul>
        
          <p style="margin-top: 10px; font-weight: bold;">Appointment Rescheduling</p>
          <ul style="margin-top: 5px; padding-left: 20px;">
            <li>If an appointment is <strong>rescheduled</strong>, the <strong>existing Zoom meeting will be updated</strong> to reflect the new date and time.</li>
            <li>The updated Zoom link (if changed) will be sent via email and updated in ClockFlex.</li>
          </ul>
        
          <p style="margin-top: 10px; font-weight: bold;">Appointment Cancellation</p>
          <ul style="margin-top: 5px; padding-left: 20px;">
            <li>If an appointment is <strong>canceled</strong>, the <strong>associated Zoom meeting will be automatically deleted</strong>.</li>
            <li>The cancellation notification will be sent to the client, and the Zoom link will no longer be valid.</li>
          </ul>
        
          <p style="margin-top: 10px; font-weight: bold;">Starting a Zoom Meeting</p>
          <ol style="margin-top: 5px; padding-left: 20px;">
            <li>Open the <strong>appointment details</strong> in ClockFlex.</li>
            <li>Click on the <strong>Zoom meeting link</strong> to join the session.</li>
            <li>You will be redirected to Zoom, where you can start the meeting.</li>
          </ol>
        
          <p style="font-size: 16px; font-weight: bold; margin-top: 20px;">Removing Zoom from ClockFlex</p>
        
          <p style="margin-top: 10px; font-weight: bold;">Step 1: Remove Zoom from ClockFlex</p>
          <ol style="margin-top: 5px; padding-left: 20px;">
            <li>Navigate to the <strong>Integrations panel</strong> in ClockFlex.</li>
            <li>Locate <strong>Zoom</strong> and click <strong>Edit</strong>.</li>
            <li>Click <strong>Disconnect Zoom</strong> to unlink the account.</li>
          </ol>
        
          <p style="margin-top: 10px; font-weight: bold;">Step 2: Remove ClockFlex from Zoom</p>
          <ol style="margin-top: 5px; padding-left: 20px;">
            <li><strong>Log into Zoom</strong> at <a href="https://marketplace.zoom.us/" target="_blank">Zoom App Marketplace</a>.</li>
            <li>Click on <strong>Manage</strong> in the top-right corner.</li>
            <li>Go to <strong>Installed Apps</strong> and find <strong>ClockFlex</strong>.</li>
            <li>Click <strong>Uninstall</strong> and confirm the removal.</li>
          </ol>
        
          <p style="font-size: 16px; font-weight: bold; margin-top: 20px;">Important Notes</p>
          <ul style="margin-top: 5px; padding-left: 20px;">
            <li><strong>Default Behavior:</strong> New appointment types will have video conferencing <strong>disabled</strong> by default.</li>
            <li>To enable video conferencing for a new appointment type:
              <ol style="padding-left: 20px;">
                <li>Go to the <strong>Integrations panel</strong>.</li>
                <li>Locate <strong>Zoom</strong> and click <strong>Edit</strong>.</li>
              </ol>
            </li>
          </ul>
        </div>
        `        }
      ],
      "description": "Instructions on integrating Zoom with ClockFlex."
    }
  ]

};
