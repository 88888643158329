import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-terms',
    imports: [FontAwesomeModule],
    templateUrl: './terms.component.html',
    styleUrl: './terms.component.scss'
})
export class TermsComponent implements OnInit {
    constructor(private titleService: Title) { }

    public ngOnInit(): void {
        this.titleService.setTitle('ClockFlex - Terms of Use');
    }
}
