import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { isUserLoggedInGuard } from './shared/services/guards/is-user-logged-in/is-user-logged-in.guard';

export const routes: Routes = [
  {
    path: 'app',
    canActivate: [isUserLoggedInGuard],
    loadChildren: () =>
      import('./modules/scheduler/scheduler.module').then(
        (m) => m.SchedulerModule
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error-pages',
    loadChildren: () =>
      import('./modules/error-pages/error-pages.module').then((m) => m.ErrorPagesModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'

    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
