import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-privacy-policy',
    imports: [],
    templateUrl: './privacy-policy.component.html',
    styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent implements OnInit {
    constructor(private titleService: Title) { }

    public ngOnInit(): void {
        this.titleService.setTitle('ClockFlex - Privacy');
    }
}
