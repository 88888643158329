import { Injectable } from '@angular/core';
import { TransactionalService } from '../transactional.service';
import { IResponse } from '../../../models/dtos/response.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationDetailService {

  constructor(
    private _transactionalService: TransactionalService,
  ) { }

  public getVersion(): Observable<IResponse<string>> {

    return this._transactionalService.get<IResponse<string>
    >(`get-application-detail`);
  }

}
